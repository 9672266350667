import React,{useEffect,useState,useRef,useLayoutEffect} from 'react'
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, Icon } from 'semantic-ui-react';

export default function Navigation() {
  const [sticky, setSticky] = useState(false);

  useEffect(()=>{
    const navScroll =()=>{
      if(window.scrollY>20){
        setSticky(true)
      }else{
        setSticky(false)
      }
    };
    window.addEventListener('scroll',navScroll);
    return ()=> window.removeEventListener('scroll',navScroll)
  })
  


  const menuItemsRef = useRef(null);
  const menuButtonRef = useRef(null);
  const mobileNavRef = useRef(null);

  useLayoutEffect(() => {
    const menuItems = menuItemsRef.current;
    const menuButton = menuButtonRef.current;
    const mobileNav = mobileNavRef.current

    menuItems.style.transform = 'translateY(-800px)';
    mobileNav.style.height = '5px'
  }, []);

  function showMenu() {
    const menuItems = menuItemsRef.current;
    const menuButton = menuButtonRef.current;
    const mobileNav = mobileNavRef.current

    if (menuItems.style.transform === 'translateY(-800px)') {
      menuItems.style.transform = 'translateY(0px)';
      mobileNav.style.height = '400px'
    } else {
      menuItems.style.transform = 'translateY(-800px)';
      mobileNav.style.height = '5px'

    }
  }

  const close =()=>{
    const menuItems = menuItemsRef.current;
    const menuButton = menuButtonRef.current;
    
    menuItems.style.transform = 'translateY(-800px)';
    menuButton.style.color = 'black';
  }
  
  const isMobile = useMediaQuery({query:'(max-width: 1130px)'})
  
  return (
  <div>
    {isMobile?
    '':
    <div id='topContact' style={{display:`${isMobile?'block':''}`}}>
    <Icon name='map marker alternate' color='white'/><a href='https://maps.app.goo.gl/cF7YuyyY7sUY9MRe8'>Za Brumlovkou 1519/4, 140 00 Praha 4</a>
    {isMobile?<br/>:''}
    <Icon name='mail' color='white'/><a href='mailto: pragueadventists@gmail.com'>pragueadventists@gmail.com</a>
    <a href="https://www.facebook.com/LighthouseInternationalSDAPrague" target="_blank" rel='noreferrer'><Icon name='facebook' color='black'/> </a>
    <a href="https://www.youtube.com/@lighthouseintsdachurch1153" target="_blank"  rel='noreferrer'><Icon name='youtube' color='black'/> </a>      
  </div>
    }
   
      
    <nav id={sticky?'sticky':"nav"} className="nav" >
      <ul>
      <li><a href="/#banner" style={{marginRight: '26.042vw'}} class="lighthouse">Lighthouse</a></li>
      <li><a href='/#about'>About Us</a></li>
      <li><a href='/#service'>Service Days</a></li>
      <li><a href='/#upcoming'>Upcoming Services</a></li>

      {window.location.pathname === '/test'?
      <li><Dropdown basic text='Departments'>
        <DropdownMenu> 
            <DropdownItem ><Link style={{color:'darkgoldenrod',fontSize:'18px'}} to={'/department/1'} reloadDocument>Lay Activity</Link></DropdownItem>
            <DropdownItem ><Link style={{color:'darkgoldenrod',fontSize:'18px'}} to={'/department/2'} reloadDocument>Sabbath School</Link></DropdownItem>
            <DropdownItem ><Link style={{color:'darkgoldenrod',fontSize:'18px'}} to={'/department/3'} reloadDocument>Education</Link></DropdownItem>

        </DropdownMenu>
          </Dropdown>
      </li>:''
}
      <li><a href="#contact">Contact Us</a></li>
      </ul>  
    </nav>

    <div id="mobileNav" className={sticky?"mobileSticky":''} ref={mobileNavRef}>
      <div id='hamBg' style={{backgroundColor:`${sticky?'darkgoldenrod':'white'}`}}>
      <a href="/#banner" id="homebtn" style={{fontSize: "20px",color:`${sticky?'white':'darkgoldenrod'}`}}>Lighthouse</a>
      <a href='https://maps.app.goo.gl/cF7YuyyY7sUY9MRe8'><Icon name='map marker alternate' color='black'/></a>
      <a href="https://www.facebook.com/LighthouseInternationalSDAPrague" target="_blank" rel='noreferrer'><Icon name='facebook' color='black'/> </a>
      <a href="https://www.youtube.com/@lighthouseintsdachurch1153" target="_blank"  rel='noreferrer'><Icon name='youtube' color='black'/> </a>      
      <p id="hamburger" onClick={showMenu} style={{fontSize:"24px",color:`${sticky?'white':'darkgoldenrod'}`,display:'inline'}} ref={menuButtonRef}> &#9776;</p>
      </div>
     
      <div id="menuItems" onClick={close} ref={menuItemsRef} className="hamitems">
        <a href="/#banner">Home</a>
        <a href='/#about'>About Us</a>
        <a href='/#service'>Service Days</a>
        <a href='/#upcoming'>Upcoming Services</a>
        <a href="#contact">Contact Us</a>
       {window.location.pathname==='/test'?
        <a>
        <Dropdown basic text='Departments'>
        <DropdownMenu> 
            <DropdownItem style={{backgroundColor:'darkgoldenrod'}}><Link style={{color:'white',fontSize:'12px'}} to={'/department/1'}>Lay Activity</Link></DropdownItem>
            <DropdownItem style={{backgroundColor:'darkgoldenrod'}}><Link style={{color:'white',fontSize:'12px'}} to={'/department/1'}>Sabbath School</Link></DropdownItem>
            <DropdownItem style={{backgroundColor:'darkgoldenrod'}}><Link style={{color:'white',fontSize:'12px'}} to={'/department/1'}>Education</Link></DropdownItem>

        </DropdownMenu>
          </Dropdown>
        </a>
:''
}


      </div>
 
    </div>  


  


    </div>
  )
}
