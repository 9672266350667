import './App.css';
import 'semantic-ui-css/semantic.min.css'
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import Navigation from '../src/Components/Navigation'
import { useMediaQuery } from 'react-responsive';
import { PaddingJs } from './JsStyles/Paddingm';
import { Analytics } from '@vercel/analytics/react';
import Contacts from './Components/Contacts'
import ComingSoon from './Pages/ComingSoon';
import Home from './Pages/Home';
import Departments from './Pages/Departments';
import Service from './Pages/Service';
import Services from './Pages/Services';
import Live from './Pages/Live';

function App() {
  const isMobile = useMediaQuery({query:'(max-width: 1130px)'})
  return (
    <BrowserRouter >
    <Navigation/>
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/test' element={<Home/>}/>
      <Route path='/department/:id' element={<Departments/>}/>
      <Route path='/service/:id' element={<Service/>}/>
      <Route path='/services' element={<Services/>}/>
      <Route path='/live' element={<Live/>}/>
      
     

    </Routes>
    <Contacts/> 
    <Analytics/>
    </BrowserRouter>
  );
}

export default App;
