import React from 'react'
import { SermonData } from '../Data/SermonData'
import { useParams } from 'react-router-dom'

export default function Service() {
    const {id} = useParams()
  return (
    <div style={{height:'400px'}} id="banner" className="banner">
        <br/><br/>
    <h1>{`${SermonData[Number(id)-1].type}, ${SermonData[Number(id-1)].date}`}</h1>
    <h1 style={{fontSize:'18px'}}>{SermonData[Number(id)-1].time}</h1>

  </div>
  )
}
