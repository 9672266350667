import { Divider } from "semantic-ui-react"
export const DepartmentInfo= [
    {
        id:1,
        department:'Lay Activity',
        page: <div className='Card'>
        <Divider horizontal><h2>Department Information</h2></Divider>
        <>
        <p>To offer one language worship through actively involving English Speaking Adventists from all over the world living in the Czech Republic to the great commission <span style={{fontWeight: 'bold', color: 'darkgoldenrod'}}>(Mission, Sabbath worship, social meeting)</span>, through spiritual growth and evangelize to increase church membership of the Czech Conference.
        </p>
        </>
        <br/>
        </div>,
        leaders:[{
            name:'Name',
            number:'777 555 444',
            email: 'ggg@gmail.com',
            picture:'https://corporate.bestbuy.com/wp-content/uploads/2022/06/Image-Portrait-Placeholder.jpg',
            },
            {
            name:'Name 2',
            number:'333 222 111',
            email: 'gggsss@gmail.com',
            picture:'https://corporate.bestbuy.com/wp-content/uploads/2022/06/Image-Portrait-Placeholder.jpg',
            }
    ]
    },
    {
        id:2,
        department:'Sabbath School',
        page: <div className='Card'>
        <Divider horizontal><h2>Department Information</h2></Divider>
        <>
        <p>To offer one language worship through actively involving English Speaking Adventists from all over the world living in the Czech Republic to the great commission <span style={{fontWeight: 'bold', color: 'darkgoldenrod'}}>(Mission, Sabbath worship, social meeting)</span>, through spiritual growth and evangelize to increase church membership of the Czech Conference.
        </p>
        </>
        <br/>
        </div>,
        leaders:[{
            name:'Name',
            number:'777 555 444',
            email: 'ggg@gmail.com',
            picture:'https://corporate.bestbuy.com/wp-content/uploads/2022/06/Image-Portrait-Placeholder.jpg',
            },
            {
            name:'Name 2',
            number:'333 222 111',
            email: 'gggsss@gmail.com',
            picture:'https://corporate.bestbuy.com/wp-content/uploads/2022/06/Image-Portrait-Placeholder.jpg',
            }
    ]
    },
    {
        id:3,
        department:'Education',
        page: <div className='Card'>
        <Divider horizontal><h2>Department Information</h2></Divider>
        <>
        <p>To offer one language worship through actively involving English Speaking Adventists from all over the world living in the Czech Republic to the great commission <span style={{fontWeight: 'bold', color: 'darkgoldenrod'}}>(Mission, Sabbath worship, social meeting)</span>, through spiritual growth and evangelize to increase church membership of the Czech Conference.
        </p>
        </>
        <br/>
        </div>,
        leaders:[{
            name:'Name',
            number:'777 555 444',
            email: 'ggg@gmail.com',
            picture:'https://corporate.bestbuy.com/wp-content/uploads/2022/06/Image-Portrait-Placeholder.jpg',
            },
            {
            name:'Name 2',
            number:'333 222 111',
            email: 'gggsss@gmail.com',
            picture:'https://corporate.bestbuy.com/wp-content/uploads/2022/06/Image-Portrait-Placeholder.jpg',
            }
    ]
    },

]