import React from 'react'
import { Loader } from 'semantic-ui-react'

export default function Live() {
  return (
    <>
    <div style={{height:'400px'}} id="banner" className="banner">
    <br/><br/>
<h1>Live Streams</h1>
</div>

<h1 style={{textAlign:'center'}}>Coming soon..</h1>
<Loader size='huge' active/>
</>

  )
}
