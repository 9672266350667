export const SermonData = [
    {
        id:1,
        type: 'Sabbath Worship',
        speaker:'Ps. Avetik',
        title:'Title',
        verse: 'John 3:16',
        date:'Saturday May 24 2024',
        sort: new Date('May 24 2024'),
        time:'14:30 - 18:30',
        location:'Za Brumlovkou 1519/4, 140 00 Praha 4',
        link: 'https://maps.app.goo.gl/PG1vicHB6f4tqTcm6',
        lunch: true
    },
    {
        id:2,
        type: 'Sabbath Worship',
        speaker:'Dr. Milan Moskala',
        title:'Title',
        verse: 'John 3:16',
        date:'Saturday June 15 2024',
        sort: new Date('June 15 2024'),
        time:'14:30 - 18:30',
        location:'Za Brumlovkou 1519/4, 140 00 Praha 4',
        link: 'https://maps.app.goo.gl/PG1vicHB6f4tqTcm6',
        lunch: false
    },
    {
        id:3,
        type: 'Sabbath Worship',
        speaker:'Ps. Balcar',
        title:'Title',
        verse: 'John 3:16',
        date:'Saturday June 22 2024',
        sort: new Date('June 22 2024'),
        time:'14:30 - 18:30',
        location:'Za Brumlovkou 1519/4, 140 00 Praha 4',
        link: 'https://maps.app.goo.gl/PG1vicHB6f4tqTcm6',
        lunch: true
    },
    {
        id:4,
        type: 'Sabbath Worship',
        speaker:'Augustine Yeboah',
        title:'Title',
        verse: 'John 3:16',
        date:'Saturday June 29 2024',
        sort: new Date('June 29 2024 16:40:27 GMT+0200'),
        time:'14:30 - 16:40',
        location:'Za Brumlovkou 1519/4, 140 00 Praha 4',
        link: 'https://maps.app.goo.gl/PG1vicHB6f4tqTcm6',
        lunch: false
    },
    {
        id:5,
        type: 'Sabbath Worship (Prayer & Fasting)',
        speaker:'Augustine Yeboah',
        title:'Title',
        verse: 'John 3:16',
        date:'Saturday July 6 2024',
        sort: new Date('July 6 2024 16:40:27 GMT+0200'),
        time:'14:30 - 16:40',
        location:'Za Brumlovkou 1519/4, 140 00 Praha 4',
        link: 'https://maps.app.goo.gl/PG1vicHB6f4tqTcm6',
        lunch: false
    },
    {
        id:6,
        type: 'Sabbath Worship',
        speaker:'Augustine Yeboah',
        title:'Title',
        verse: 'John 3:16',
        date:'Saturday July 20 2024',
        sort: new Date('July 20 2024 16:40:27 GMT+0200'),
        time:'14:00 - 16:40',
        location:'Za Brumlovkou 1519/4, 140 00 Praha 4',
        link: 'https://maps.app.goo.gl/PG1vicHB6f4tqTcm6',
        lunch: false
    },
    {
        id:7,
        type: `Children's Sabbath`,
        speaker:'Children',
        title:'Title',
        verse: 'John 3:16',
        date:'Saturday July 27 2024',
        sort: new Date('July 27 2024 16:40:27 GMT+0200'),
        time:'14:00 - 16:40',
        location:'Za Brumlovkou 1519/4, 140 00 Praha 4',
        link: 'https://maps.app.goo.gl/PG1vicHB6f4tqTcm6',
        lunch: true
    },
    {
        id:8,
        type: `Sabbath Worship in the park`,
        speaker:'Children',
        title:'Title',
        verse: 'John 3:16',
        date:'Saturday August 3 2024',
        sort: new Date('August 3 2024 16:40:27 GMT+0200'),
        time:'14:00 - 16:40',
        location:'Za Brumlovkou 1519/4, 140 00 Praha 4',
        link: 'https://maps.app.goo.gl/PG1vicHB6f4tqTcm6',
        lunch: true
    },

    

   

]