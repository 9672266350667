import React, { useState,useEffect } from 'react'
import { Divider,Icon, Image, Card,CardDescription} from 'semantic-ui-react'
import { useMediaQuery } from 'react-responsive'
import { EventsData } from '../Data/Events'
import { Fade } from 'react-awesome-reveal'
import { SermonData } from '../Data/SermonData'
import { Link } from 'react-router-dom'


export default function Home() {
  const isMobile = useMediaQuery({query:'(max-width: 1130px'})

  const [currentDate,setCurrentDate] = useState(3)

  useEffect(() => {
    const updateDate = ()=>{
      
    }

  }, [currentDate])
  

  const sortedSermons = [...SermonData].sort((a,b)=>a.sort-b.sort).filter(sermon=>sermon.sort>=new Date());
  sortedSermons.length=1
  console.log(sortedSermons)
  

  const sermonList = sortedSermons.map((s)=>(
      <>
      <h1>{s.type}</h1>
      <p><Icon name='map'/><Link to={s.link} >{s.location}</Link></p>
      <p><Icon name='calendar'/>{s.date}</p>
      <p><Icon name='clock'/>{s.time}</p>
      {s.lunch?<p><Icon name='food'/>Dinner after service</p>:''}
      </>

  ))
  

  return (
    <>
    <div id="banner" className="banner">
        <h1>Lighthouse</h1>
        <p>Lighthouse International SDA Prague</p>
        
        <p><a href="#join" id="partofus" className='button-1'>Be part of us</a></p>
        
  </div>
    <div id='join' className='join'>
    <iframe id ='video'src="https://www.youtube.com/embed/9NGygiZlkNU" title="We Are Inviting you to Prague International SDA Church - Lighthouse! :)" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"></iframe>
    </div> 

    <Fade>
    <div id="about" className="about">
      <br/>
      <br/>
      <Card.Group items={3}  itemsPerRow={isMobile?0:3} >
        <Card centered  fluid className='Card'>
      <Divider horizontal><h2>Background</h2></Divider>
      <CardDescription>
      <p>At a meeting requested by the English speaking group on September 20, 2020 and attended by Bethany church board, the Czech Conference President and representative from the English-Speaking Adventists, a proposal to establish full English Speaking Church was agreed.</p>

       <p> The English church is to serve the spiritual needs of English Speaking Adventists who are struggling to maintain their faith or utilize their talents effectively due to language barrier.</p>
        
        <p>On February 19, 2022, Bethany Church commissioned the English-Speaking Church at a joint service.
        The group is multicultural with member coming from over 15 countries including
        <span style={{fontWeight: 'bold', color: 'darkgoldenrod'}}> Brazil, Burma, Colombia, Czech Republic, Ghana, India, Kenya, Nigeria, Philippines, Sierra Leone, Slovakia, South Africa, Tahiti, Ukraine, Mauritius and Morocco</span>
        </p>
        <br/>
        </CardDescription>
        </Card>


        <Card centered fluid className='Card'>
        <Divider horizontal><h2>Our Mission</h2></Divider>
        <CardDescription>
        <p>To offer one language worship through actively involving English Speaking Adventists from all over the world living in the Czech Republic to the great commission <span style={{fontWeight: 'bold', color: 'darkgoldenrod'}}>(Mission, Sabbath worship, social meeting)</span>, through spiritual growth and evangelize to increase church membership of the Czech Conference.
        </p>
        </CardDescription>
        <br/>
        </Card>


        <Card centered fluid className='Card'>
        <Divider horizontal><h2>Vision</h2></Divider>
        <CardDescription>
        <p>To expedite the soon coming of our Lord and Savior to end all suffering in the world.
          "John 8:12 , <span style={{fontWeight: 'bold', color: 'darkgoldenrod'}}>Jesus says, I am the light of the world. He who follows me shall not walk in darkness but have the light of life"</span></p>
         </CardDescription> 
          </Card>
        </Card.Group>
    </div>
    </Fade>
  

<Fade>

    <div id='service' className='service' style={{textAlign:'center'}}>
   <h1 style={{color:'darkgoldenrod', textAlign:'center',paddingBottom:'20px'}}>SERVICE DAYS</h1>
    {<div  className='card-group' >

  <div fluid className='Card'>
    <Image src='https://i0.wp.com/adivineencounter.com/wp-content/uploads/2019/05/How-to-Keep-the-Sabbath-According-to-the-Bible-featured.jpg?resize=300%2C300&ssl=1' width={'300px'} centered/>
    <h1>Sabbath Worship</h1>
    <p>Every Saturday from <span style={{fontWeight:'bold'}}>14:00 to 16:40</span></p>
    </div>

  <div fluid className='Card'>
  <Image src='https://clevermuttportal.com/_gbc_media/panel-blog2-thumbnail.png' width={'300px'} centered/>
    <h1>Mid Week</h1>
    <p>Every Wednesday from <span style={{fontWeight:'bold'}}>19:00 to 20:00 via Zoom</span></p>
    </div>

  <div fluid className='Card'>
  <Image src='https://e5hougg55fx.exactdn.com/wp-content/uploads/2021/04/How-to-Memorize-the-Books-of-The-Bible-feature-image-300x300.jpg?strip=all&lossy=1&quality=60&ssl=1' width={'300px'} centered/>
    <h1>Vesper Service</h1>
    <p>Every Friday from <span style={{fontWeight:'bold'}}>19:00 to 20:00 via Zoom</span></p>
    </div>
  
</div>
  }
    </div>
    </Fade>

  


<Fade>
        <div className='services' id='upcoming'>

        <h2>NEXT SABBATH WORSHIP</h2>
        <br/>


            <div className='info'>

            <>{sermonList}</>

          </div>
          <br/>

         {/* <Link to={'/services'} style={{color:'black'}} className='button-2' reloadDocument>Upcoming services</Link>*/}
        {/*<Link to={SermonData[2].link}style={{color:'black'}} target='blank'>

        <br/>

        <div className='info'>
        <h1>Sabbath Worship</h1>
        <p><Icon name='map'/>{SermonData[4].location}</p>
        <p><Icon name='calendar'/>Saturday May 25 </p>
        <p><Icon name='clock'/>14:00 - 18:30</p>
        </div>

        </Link>
*/}

        </div>
</Fade>


   







    
    </>
  )
}
