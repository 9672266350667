import React, { useState } from 'react'
import { useForm,ValidationError } from '@formspree/react'
import { Button, Divider, Form, Header, FormField, FormGroup, FormInput, Icon, Input, Label, Message, Modal, ModalActions, ModalContent, TextArea } from 'semantic-ui-react'

export default function Contacts() {

  const [open,setOpen] = useState(true)


  const [state,handleSubmit,reset] = useForm('xwkglyeo')
  if (state.succeeded){
    return   <div id='contact'>      <div id='contactInfo'>
    <h2>Contact Information</h2>
    <p>To offer one language worship through actively involving English Speaking Adventists from all over the world living in the Czech Republic </p>

    <br/><br/>
    
    <a style={{cursor:'pointer'}} id="email" href="mailto: pragueadventists@gmail.com" target="_blank" rel='noreferrer'><Icon name='mail'/> pragueadventists@gmail.com</a>
<br/><a style={{cursor:'pointer'}}id="number" href="tel: +420 770 666 459" target="_blank" rel='noreferrer'><Icon name='phone'/> +420 770 666 459</a>
<br/><a style={{cursor:'pointer'}}href="https://maps.app.goo.gl/cF7YuyyY7sUY9MRe8" target="_blank" rel='noreferrer'><Icon name='map marker alternate' />  Za Brumlovkou 1519/4, 140 00 Praha 4</a>
<br/><a href="https://www.facebook.com/LighthouseInternationalSDAPrague" target="_blank" rel='noreferrer'><Icon name='facebook' />Prague International SDA Church - Lighthouse </a>
<br/><a href="https://www.youtube.com/@lighthouseintsdachurch1153" target="_blank"  rel='noreferrer'><Icon name='youtube'/>Lighthouse INT SDA Church</a>

  </div>

  <div id='form' style={{textAlign:'center'}}>
    <Icon name='check' color='green' size='huge' style={{textAlign:'center'}}/>
    <h2>Message Sent</h2>
    <Modal open={open}>
      <ModalContent><h2>Thank you for your message</h2></ModalContent>
      <ModalActions >
        <Button onClick={()=>{setOpen(false)}}>Close</Button>
      </ModalActions>
    </Modal>
  </div>
</div>
  }



  return (
    <div id='contact' className='contact'>

      <div id='contactInfo'>
        <h2>Contact Information</h2>
        <p> The English church is to serve the spiritual needs of English Speaking Adventists who are struggling to maintain their faith or utilize their talents effectively due to language barrier.</p>

        <br/><br/>
        
        <a style={{cursor:'pointer'}} id="email" href="mailto: pragueadventists@gmail.com" target="_blank" rel='noreferrer'><Icon name='mail'/> pragueadventists@gmail.com</a>
    <br/><a style={{cursor:'pointer'}}id="number" href="tel: +420 770 666 459" target="_blank" rel='noreferrer'><Icon name='phone'/> +420 770 666 459</a>
    <br/><a style={{cursor:'pointer'}}href="https://maps.app.goo.gl/cF7YuyyY7sUY9MRe8" target="_blank" rel='noreferrer'><Icon name='map marker alternate' />  Za Brumlovkou 1519/4, 140 00 Praha 4</a>
    <br/><a href="https://www.facebook.com/LighthouseInternationalSDAPrague" target="_blank" rel='noreferrer'><Icon name='facebook' />Prague International SDA Church - Lighthouse </a>
    <br/><a href="https://www.youtube.com/@lighthouseintsdachurch1153" target="_blank"  rel='noreferrer'><Icon name='youtube'/>Lighthouse INT SDA Church</a>

      </div>
    

  <div id='form'>
  <h2>Contact Information</h2>
<br/>
    <Form onSubmit={handleSubmit}>

<FormGroup unstackable widths={'equal'}>
<FormField control={'input'}  label='Your Email'  width={'5'}
  id="email"
  type="email" 
  name="Email"
  
  required={true}
/>
<ValidationError 
  prefix="Email" 
  field="Email"
  errors={state.errors}
/>

<FormField control={'input'} label='Your Name'  width={'5'}
id='name'
type='text'
name='Name'
/>
</FormGroup>

<FormField control={'textarea'} label='Your Message' inline
  id="message"
  name="Message"
  placeholder='Type your message here'
  required={true}

 
/>
<ValidationError 
  prefix="Message" 
  field="Message"
  errors={state.errors}
/>
<button className='button-2' style={{fontWeight:'light'}} type="submit" disabled={state.submitting}>
  Send Message
</button>
</Form>

</div>

</div>
  )
}
