import React from 'react'
import { DepartmentInfo } from '../Data/DepartmentInfo'
import {Divider,} from 'semantic-ui-react'
import { useMediaQuery } from 'react-responsive'
import { useParams } from 'react-router-dom'

export default function Departments() {
  const {id}=useParams()
    const isMobile = useMediaQuery({query:'(max-width: 1130px'})

  return (
    <div>

<div style={{height:'200px'}} id="banner" className="banner">
    <h1>{DepartmentInfo[Number(id-1)].department}</h1>

  </div>


    <div id="about" className="about" style={{paddingTop:isMobile?`100px`:`100px`}}>  
          <br/>
          <br/>
            <div className='Card'>
        
            </div>


           {DepartmentInfo[Number(id)-1].page}


            <div className='Card'>
        
              </div>


        </div>
        <Divider horizontal><h2>Department Heads</h2></Divider>
{/*
  DepartmentInfo.map((department)=>(
        <div id='details'>
          {department.leaders.map((leader)=>(
            <>
    <img alt='thumbnail' src={leader.picture} width='200px'/>
    <h1>{leader.name}</h1>
    <a href={`tel:1234567890`}>Number:{leader.number}</a>
    <br/>
    <a href={`mailto:shgsdgsghs@gmai.com`}>Email:{leader.email}</a>
    <br/>
    <img alt='country' src='https://e7.pngegg.com/pngimages/817/110/png-clipart-flag-of-the-czech-republic-challenge-prague-flags-of-the-world-czech-republic-flag-blue-angle-thumbnail.png' width={'30px'}/>

    <br/><br/><br/>
            </>
          )
  )}
    </div>
  ))
          */}


        <div id='details'>
    <img alt='thumbnail' src={'https://corporate.bestbuy.com/wp-content/uploads/2022/06/Image-Portrait-Placeholder.jpg'} width='200px'/>
    <h1>Name</h1>
    <a href={`tel:1234567890`}>Number:776776776</a>
    <br/>
    <a href={`mailto:shgsdgsghs@gmai.com`}>Email:hshjaka@gmai.com</a>
    <br/>
    <img alt='country' src='https://e7.pngegg.com/pngimages/817/110/png-clipart-flag-of-the-czech-republic-challenge-prague-flags-of-the-world-czech-republic-flag-blue-angle-thumbnail.png' width={'30px'}/>
    </div>

    </div>

  )
}