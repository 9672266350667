import React from 'react'
import { Link } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'
import { SermonData } from '../Data/SermonData'

export default function Services() {
  return (
    <>

<div style={{height:'250px'}} id="banner" className="banner">
        <br/><br/>
    <h1>Services</h1>

  </div>


<div id='allServices'>

        {SermonData.map((s)=>(
          <div>
              <h1>{s.type}</h1>
              <p><Icon name='map'/>{s.location}</p>
              <p><Icon name='calendar'/>{s.date}</p>
              <p><Icon name='clock'/>{s.time}</p>
              {s.lunch?<p><Icon name='food'/>Dinner after service</p>:''}
          </div>
          ))}

</div>


    </>
  )
}
