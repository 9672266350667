export const EventsData = [
    /*
    {
        id:1,
        name: 'Worship with Ukranian SDA church',
        date: 'Saturday October 14 2023',
        location: 'Na Doubkové 2040/8, 150 00 Praha 5-Smíchov',
    },
    */
    {
        id:1,
        name: 'Family Day',
        date: 'Saturday February 17 2024',
        newDate: new Date('Saturday February 17 2024'),
        time: '2:00PM',
        location: 'Za Brumlovkou 1519/4, 140 00 Praha 4',
        link: 'https://maps.app.goo.gl/YBreKfAt6AZB7szH8'
    },
    {
        id:2,
        name:'Youth Day Service',
        date: 'Saturday March 03 2024',
        newDate: new Date('Saturday March 03 2024'),
        time: '14:00',
        location:'Za Brumlovkou 1519/4, 140 00 Praha 4',
        link: 'https://www.facebook.com/photo/?fbid=393076920132256&set=a.135989055841045'

    }


]